<template>
  <div>
    <b-modal
      id="modal-exempt"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Exempt Fee"
    >
      <b-row class="">
        <b-col md="12">
          <b-form-group
            label="Reason"
            invalid-feedback="Reason is required."
            ref="ex_reason"
          >
            <b-form-textarea
              placeholder="Enter reason"
              rows="3"
              v-model.trim="exObj.reason"
            />
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="exempting"
            @click="ExemptWithNote()"
          >
            <b-spinner v-if="exempting" small type="grow" />
            <span v-else class="align-middle">Exempt</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ sidebarTitle }}</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Complaint"
              invalid-feedback="complaint is required."
              ref="complaint"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter complaint"
                name="classField"
                ref="complaint"
                v-model="compObj.complaint"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="compDate"
            >
              <flat-pickr
                ref="compDate"
                :config="config"
                v-model="compObj.date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Upload Documents"
              invalid-feedback="Documents are required"
            >
              <b-button
                ref="docs"
                @click="$refs.attachment.click()"
                variant="primary"
                :disabled="request || mydocloading"
                block
              >
                <b-spinner
                  v-if="mydocloading"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Upload</span>
              </b-button>
              <input
                type="file"
                id="attachment"
                hidden
                ref="attachment"
                @change="uploadAtt()"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="mediaLoaded"
            style="display: flex; align-items: center"
          >
            <div class="file-type">
              {{ compObj.attachment.split(".").pop().toUpperCase() }}
            </div>
            <feather-icon
              icon="Trash2Icon"
              size="22"
              class="text-danger cursor-pointer"
              @click="removeAtt()"
            />
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="saveComp()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ buttonTitle }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Resolve Complaint</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="note"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter note"
                name="classField"
                ref="note"
                v-model="resObj.note"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="resdate"
            >
              <flat-pickr
                ref="resdate"
                :config="config"
                v-model="resObj.resolvedOn"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Resolved By"
              invalid-feedback="resolved by is required."
              ref="resBy"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="resBy"
                v-model="resObj.resolvedBy"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Resolve </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Initiate Admission Fee</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <div class="mb-50">
              <b-badge variant="primary" class="font-small-3">
                Current Campus: {{ getCampus(userData.campusID) }}
              </b-badge>
            </div>
            <div class="mb-50">
              <b-badge variant="primary" class="font-small-3">
                Admitted Campus: {{ getCampus(userData.campus_Admitted) }}
              </b-badge>
            </div>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group
              label="Select Month"
              invalid-feedback="month is required."
              ref="month"
            >
              <v-select
                v-model="feeObj.months"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                :reduce="(op) => op.value"
                :clearable="false"
                label="text"
                placeholder="Select month"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-form-group
              label="Select Months"
              invalid-feedback="month is required."
              ref="month"
            >
              <!-- style="margin: 7px" -->
              <b-button
                class="btn-icon mr-50 mb-50"
                v-for="(mon, ind) in months"
                :key="ind"
                :variant="
                  feeObj.months.includes(mon.value)
                    ? 'primary'
                    : 'outline-primary'
                "
                @click="selectMonth(mon.value)"
              >
                {{ mon.value }}
              </b-button>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Select Year"
              invalid-feedback="year is required."
              ref="year"
            >
              <v-select
                v-model="feeObj.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                label="name"
                placeholder="Select year"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="saveFee()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> Create </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Receive Fee</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <div class="mb-50">
              <b-badge variant="primary" class="font-small-3">
                Current Campus: {{ getCampus(userData.campusID) }}
              </b-badge>
            </div>
            <div class="mb-50">
              <b-badge variant="primary" class="font-small-3">
                Admitted Campus: {{ getCampus(userData.campus_Admitted) }}
              </b-badge>
            </div>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Collection Account"
              invalid-feedback="Collection account is required."
              ref="acc"
            >
              <v-select
                v-model="recObj.accountID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accTypes"
                :reduce="(opt) => opt.id"
                label="account"
                :clearable="false"
                placeholder="Select account type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Receiving Date"
              invalid-feedback="receiving date is required."
              ref="rcvDate"
            >
              <flat-pickr
                :config="config"
                v-model="recObj.rcvDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <template v-if="chDetails && chDetails.valueBool">
            <b-col md="12">
              <b-form-group invalid-feedback="Organization is required.">
                <div class="d-flex justify-content-between">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Organization</label
                  >
                  <feather-icon
                    class="cursor-pointer"
                    icon="EditIcon"
                    size="20"
                    v-b-tooltip.hover.top
                    title="Organization"
                    @click="openOrg()"
                  />
                </div>
                <v-select
                  v-model="otherObj.organization"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[{ id: 0, title: 'None' }, ...orgList]"
                  :reduce="(opt) => opt.id"
                  label="title"
                  :clearable="false"
                  placeholder="Select organization"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Bank Name"
                invalid-feedback="Bank Name is required."
              >
                <b-form-input
                  v-model="otherObj.bank"
                  placeholder="Enter bank name"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Cheque No."
                invalid-feedback="Cheque No is required."
              >
                <b-form-input
                  v-model="otherObj.cheque"
                  placeholder="Enter cheque number"
                />
              </b-form-group>
            </b-col>
          </template>

          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="rec_note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model="recObj.receiveNote"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <h5>Receiving Amount: {{ totalAmount }}</h5>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="receiveFee()"
              :disabled="receiving"
              block
            >
              <b-spinner v-if="receiving" small type="grow" />
              <span v-else> Receive </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Installments</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group label="Current Balance" ref="currBal">
              <b-form-input
                disabled
                placeholder=""
                ref="currBal"
                v-model="instCurrent"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Installment Amount"
              invalid-feedback="Invalid amount entered."
              ref="instAmount"
            >
              <b-form-input
                type="number"
                placeholder="Enter amount"
                v-model="instObj.amount"
                @input="checkInstallment()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mt-1"
              @click="saveInstallment()"
              :disabled="instSaving"
              block
            >
              <b-spinner v-if="instSaving" small type="grow" />
              <span v-else>Create Installment</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility6"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Discount</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility6 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group label="Current Balance" ref="currBal">
              <b-form-input disabled placeholder="" v-model="currentBalance" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Discount"
              invalid-feedback="Invalid amount entered."
              ref="discount"
            >
              <b-form-input
                type="number"
                placeholder="Enter discount amount"
                v-model="disObj.discountAmount"
                @input="validateDiscount()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Net Balance" ref="netBal">
              <b-form-input disabled placeholder="" v-model="netBalance" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="dis_note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model="disObj.discountNote"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mt-1"
              @click="AddDiscount()"
              :disabled="savingDis"
              block
            >
              <b-spinner v-if="savingDis" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility7"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Organizations</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility7 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="12">
            <b-form-group
              label="Organization Name"
              invalid-feedback="Organization name is required."
            >
              <b-form-input
                ref="org_name"
                v-model.trim="orgObj.title"
                placeholder="Enter organization name name"
                @focusout="checkOrg()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="success"
              class="btn-icon mt-1"
              @click="saveOrg()"
              :disabled="savingOrg"
              block
            >
              <b-spinner v-if="savingOrg" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-2"
          show-empty
          :items="orgList"
          :fields="orgFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editOrg(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteOrg(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button> -->
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-tabs class="pt-1" align="start" pills>
      <b-tab title="Fee" active>
        <template #title>
          <feather-icon icon="BookmarkIcon" size="18" />
          <span style="font-size: 16">Fee</span>
        </template>
        <b-card-title class="mb-75">Fee Dues</b-card-title>
        <b-card>
          <div
            class="d-flex flex-wrap align-items-center justify-content-end mb-1"
          >
            <div class="">
              <b-button
                variant="outline-secondary"
                class="btn-icon mr-50"
                @click="print()"
                :disabled="printing"
              >
                <span>Print Challan</span>
                <b-spinner v-if="printing" small class="ml-50" />
              </b-button>
              <b-button
                v-if="
                  reportDomain === 'myskoolhpgs' &&
                  userData.status.toLowerCase() == 'registered'
                "
                variant="success"
                class="btn-icon"
                @click="openInit()"
                :disabled="
                  !admObj ||
                  !admObj.valueBool ||
                  !admObj.value ||
                  dues.find((el) =>
                    el.feesType.feeType.toLowerCase().includes('registration')
                  )
                "
              >
                Initiate Admission Fee
              </b-button>
            </div>
          </div>

          <b-table
            ref="dueTable"
            class=""
            show-empty
            :items="dues"
            :fields="feesFields"
            responsive
            hover
            :busy="gridLoading"
            selectable
            :select-mode="'multi'"
            @row-selected="onRowSelect($event)"
          >
            <template #table-busy>
              <div class="d-flex justify-content-center my-2">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>

            <template #head(feeType)="data">
              <div>
                <b-form-checkbox
                  inline
                  :checked="
                    selectedID.length > 0 && selectedID.length == dues.length
                  "
                  @change="(e) => setSelectAll(e)"
                >
                  <span class="font-small-2">{{ data.label }}</span>
                </b-form-checkbox>
              </div>
            </template>

            <template #cell(feeType)="data">
              <b-form-checkbox
                inline
                :checked="selectedID.includes(data.item.fees.id)"
                @change="(e) => setSelected(e, data.index)"
              >
                {{ data.item.feesType.feeType }}
              </b-form-checkbox>
            </template>
            <template #cell(monthYear)="data">
              <b-badge variant="light-primary">
                {{ data.item.fees.monthYear }}
              </b-badge>
              <br />
              <b-badge
                variant="light-danger"
                style="margin-top: 4px"
                v-if="data.item.fees.status == 'hold'"
              >
                On Hold
              </b-badge>
            </template>
            <template #cell(lateFee)="data">
              <b-badge variant="light-primary">
                {{ data.item.fees.lateFee }}
              </b-badge>
            </template>
            <template #cell(timelyDiscount)="data">
              <b-badge variant="light-primary">
                {{ data.item.fees.timelyDiscount }}
              </b-badge>
            </template>
            <template #cell(balance)="data">
              <b-badge variant="light-primary">
                {{ data.item.fees.balance }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                v-b-tooltip.hover.bottomleft
                title="Discount"
                @click="openDiscount(data.item)"
              >
                <feather-icon icon="PercentIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                v-b-tooltip.hover.bottomleft
                title="Installment"
                @click="openInst(data.item)"
              >
                <feather-icon icon="ShuffleIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                v-b-tooltip.hover.bottomleft
                title="Fee Exempt"
                @click="openExempt(data.item.fees.id)"
              >
                <!-- @click="Exempt(data.item.fees.id)" -->
                <feather-icon icon="StarIcon" />
              </b-button>
            </template>
          </b-table>

          <div
            v-if="
              reportDomain === 'myskoolhpgs' &&
              (['registered', 'enrolled'].includes(
                userData.status.toLowerCase()
              ) ||
                (userData.status.toLowerCase() == 'present' && showReceive)) &&
              dues.length > 0
            "
            class="d-flex justify-content-end"
          >
            <b-button variant="success" class="btn-icon" @click="openRec()">
              Receive Fee
            </b-button>
          </div>
        </b-card>
        <b-card-title class="mb-75">Fees History</b-card-title>
        <user-view-user-timeline-card
          :history="history"
          :duesTotal="duesTotal"
        />
      </b-tab>
      <b-tab title="Attendance" lazy>
        <template #title>
          <feather-icon icon="CheckCircleIcon" size="18" />
          <span style="font-size: 16">Attendance</span>
        </template>
        <b-card>
          <b-button variant="outline-primary" @click="loadAttendance('week')">
            This Week
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadAttendance('month')"
            class="ml-1"
          >
            This Month
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadAttendance('year')"
            class="ml-1"
          >
            This Year
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadAttendance('last year')"
            class="ml-1"
          >
            Last Year
          </b-button>

          <b-table
            responsive
            :items="attendance"
            :fields="attFields"
            class="mb-0 mt-2"
            show-empty
            :busy="dataLoading"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="data.value === 'P' ? 'light-success' : 'light-danger'"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(date)="data">
              <b-badge variant="light-primary">
                {{
                  new Date(data.value).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </b-badge>
            </template>
            <template #cell(time)="data">
              <b-badge variant="light-primary">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(data.item.date))
                }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-tab>
      <b-tab title="Complaints" lazy>
        <template #title>
          <feather-icon icon="InfoIcon" size="18" />
          <span style="font-size: 16">Complaints</span>
        </template>
        <b-card>
          <div class="d-flex">
            <b-button
              variant="primary"
              class="mr-1"
              @click="openComp()"
              v-if="!byparent"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span style="font-size: 16">Complaints</span>
            </b-button>
            <div style="margin-left: auto">
              <b-button
                :variant="byparent ? 'outline-primary' : 'primary'"
                class="mr-1"
                @click="loadComplaints()"
              >
                By School
              </b-button>
              <b-button
                :variant="byparent ? 'primary' : 'outline-primary'"
                class="mr-1"
                @click="loadParent()"
              >
                By Parents
              </b-button>
            </div>
          </div>

          <b-table
            responsive
            :items="complaints"
            :fields="byparent ? parentFields : compFields"
            show-empty
            :busy="dataLoading"
            class="mb-0 mt-2"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>

            <template #cell(date)="data">
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.us.date).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </b-badge>
            </template>
            <template #cell(name)="data">
              {{
                byparent
                  ? data.item.us.about
                  : !data.item.ts
                  ? "Admin"
                  : data.item.ts.name
              }}
            </template>
            <template #cell(complaint)="data">
              {{ byparent ? data.item.us.details : data.item.us.complaint }}
            </template>

            <template #cell(parActions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="resolve(data.item.us)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="EditComp(data.item.us)"
              >
                <feather-icon icon="EditIcon" class="" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="DeleteComp(data.item.us.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-tab>
      <b-tab title="Assessment" lazy>
        <template #title>
          <feather-icon icon="PenToolIcon" size="18" />
          <span style="font-size: 16">Assessment</span>
        </template>
        <!-- <b-card-text> Graph Subject wise Subject Filter </b-card-text> -->
        <b-card>
          <b-button
            v-if="false"
            variant="outline-primary"
            class="mr-1"
            v-for="ex in examTypes"
            :key="ex.id"
          >
            {{ ex.examType }}
          </b-button>

          <b-table
            v-if="!marksGrid"
            responsive
            :items="assessments"
            :fields="resultFields"
            show-empty
            small
            class="mb-0 mt-2"
          >
            <template #cell(examType)="data">
              <b-badge variant="light-primary" class="m-50">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(session)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>

            <template #cell(marks)="data">
              <b-badge variant="light-primary">
                {{ data.item.results.obtainedMarks }} /
                {{ data.item.results.totalMarks }}
              </b-badge>
            </template>

            <template #cell(percentage)="data">
              <b-badge variant="light-primary">
                {{ data.item.results.percentage }} <span>%</span>
              </b-badge>
            </template>

            <template #cell(rank)="data">
              <b-badge
                :variant="
                  data.item.results.rank == '1st' ||
                  data.item.results.rank == '2nd' ||
                  data.item.results.rank == '3rd'
                    ? 'light-success'
                    : 'light-primary'
                "
              >
                {{ data.item.results.rank }}
              </b-badge>
            </template>

            <template #cell(grade)="data">
              <b-badge
                v-if="data.item.results.grade"
                :variant="
                  data.item.results.grade.toLowerCase() == 'fail' ||
                  data.item.results.grade.toLowerCase() == 'failed' ||
                  data.item.results.grade.toLowerCase() == 'f'
                    ? 'light-danger'
                    : 'light-primary'
                "
              >
                {{ data.item.results.grade }}
              </b-badge>
            </template>
            <template #cell(result)="data">
              <b-badge
                :variant="
                  data.item.results.result == 'PASSED'
                    ? 'light-success'
                    : 'light-danger'
                "
              >
                {{ data.item.results.result }}
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="View(data.item.marks)"
              >
                <feather-icon icon="EyeIcon" class="" />
              </b-button>
            </template>
          </b-table>

          <b-button
            v-if="marksGrid"
            variant="outline-secondary"
            class=""
            @click="marksGrid = false"
          >
            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
            <span class="align-middle">Back</span>
          </b-button>

          <b-table
            v-if="marksGrid"
            responsive
            :items="marksData"
            :fields="marksFields"
            show-empty
            small
            class="mb-0 mt-2"
          >
            <template #cell(subject)="data">
              <b-badge variant="light-primary" class="m-50">
                {{ data.value }}
              </b-badge>
            </template>

            <template #cell(attendance)="data">
              <b-badge
                v-if="data.item.marks.attendance"
                :variant="
                  data.item.marks.attendance == 'P'
                    ? 'light-success'
                    : 'light-danger'
                "
              >
                {{ data.item.marks.attendance }}
              </b-badge>
            </template>

            <template #cell(marks)="data">
              <b-badge variant="light-primary">
                {{ data.item.marks.obtained }} /
                {{ data.item.marks.total }}
              </b-badge>
            </template>

            <template #cell(grade)="data">
              <b-badge
                v-if="data.item.marks.meta_sub_grade"
                :variant="
                  data.item.marks.meta_sub_grade.toLowerCase() == 'fail' ||
                  data.item.marks.meta_sub_grade.toLowerCase() == 'failed' ||
                  data.item.marks.meta_sub_grade.toLowerCase() == 'f'
                    ? 'light-danger'
                    : 'light-primary'
                "
              >
                {{ data.item.marks.meta_sub_grade }}
              </b-badge>
            </template>

            <template #cell(percentage)="data">
              <b-badge variant="light-primary">
                {{ data.item.marks.meta_subj_percenatge }} <span>%</span>
              </b-badge>
            </template>

            <template #cell(rank)="data">
              <b-badge
                :variant="
                  data.item.marks.rank == '1st' ||
                  data.item.marks.rank == '2nd' ||
                  data.item.marks.rank == '3rd'
                    ? 'light-success'
                    : 'light-primary'
                "
              >
                {{ data.item.marks.rank }}
              </b-badge>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.item.marks.status == 'PASSED'
                    ? 'light-success'
                    : 'light-danger'
                "
              >
                {{ data.item.marks.status }}
              </b-badge>
            </template>
          </b-table>

          <b-row align-h="end" class="mt-2">
            <b-col md="3">
              <!-- <v-select
                v-model="assess.subject"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjects"
                :clearable="false"
                ref="subject"
                @change="Check()"
                placeholder="Select subject"
              /> -->
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body v-if="showChart">
          <b-card-header class="p-1">
            <div>
              <b-card-title>Assessment Summary</b-card-title>
              <!-- <b-card-sub-title></b-card-sub-title> -->
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="400"
              :data="lineChart.data"
              :options="lineChart.options"
            />
            <!-- :plugins="plugins" -->
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab title="PTM" lazy>
        <template #title>
          <feather-icon icon="Edit2Icon" size="18" />
          <span style="font-size: 16">PTM</span>
        </template>
        <b-card-title class="mb-75">Parents Teacher Meeting</b-card-title>
        <b-card>
          <div v-if="!addPtm">
            <b-button variant="primary" @click="open()">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Summary</span>
            </b-button>
            <b-table
              responsive
              :items="ptmData"
              :fields="ptmFields"
              class="mb-0 mt-2"
              show-empty
              :busy="ptmLoading"
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 30px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #cell(date)="data">
                <b-badge variant="light-primary">
                  {{
                    new Date(data.value).toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
          </div>
          <b-row
            v-else
            class="d-flex justify-content-between align-items-center"
          >
            <b-col md="3">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required."
                ref="date"
              >
                <flat-pickr
                  :config="config"
                  v-model="ptmObj.date"
                  class="form-control"
                  placeholder="Select date"
                  @input="CheckDate()"
                />
              </b-form-group>
            </b-col>
            <b-col md="9">
              <b-form-group
                label="Summary"
                invalid-feedback="Summary is required."
              >
                <b-form-input
                  placeholder="Enter summary."
                  ref="summary"
                  v-model="ptmObj.summary"
                  @focusout="CheckSummary()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="text-right">
              <b-button
                variant="outline-secondary"
                class="mr-50"
                @click="addPtm = false"
              >
                <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                <span class="align-middle">Back</span>
              </b-button>
              <b-button
                variant="success"
                @click="Add()"
                :disabled="request"
                class="mr-50"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <!-- <b-tab title="Resoruces">
        <template #title>
          <feather-icon icon="BookIcon" size="18" />
          <span style="font-size: 18px">Resoruces</span>
        </template>
        <b-card>
          <b-card-title>Resources</b-card-title>
          <b-table responsive :items="resources" class="mb-0 mt-2">
            <template #cell(subject)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(notes)="data">
              <b-button variant="primary" class="btn-icon">
                <feather-icon icon="FileIcon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-tab> -->
    </b-tabs>
  </div>
  <!-- <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Current Plan
      </h5>
      <b-badge variant="light-primary">
        Basic
      </b-badge>
      <small class="text-muted w-100">July 22, 2021</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">5 Users</span>
        </li>
        <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Upgrade Plan
      </b-button>
    </b-card-body>
  </b-card> -->
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BTab,
  BTabs,
  BCardText,
  BCardSubTitle,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BSpinner,
  BSidebar,
  BForm,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ChartjsComponentLineChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentLineChart.vue";
import { mapActions, mapGetters } from "vuex";
import { $themeColors } from "@themeConfig";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  components: {
    BCard,
    BSidebar,
    BForm,
    BBadge,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BButton,
    BTab,
    BTabs,
    BCardText,
    BCardSubTitle,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormTextarea,
    UserViewUserTimelineCard,
    vSelect,
    flatPickr,
    ChartjsComponentLineChart,
  },
  props: {
    dues: {
      type: Array,
      required: true,
    },
    history: {
      type: Array,
      required: true,
    },
    duesTotal: {
      type: Number,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    gridLoading: {
      type: Boolean,
    },
    showReceive: {
      type: Boolean,
    },
    getCampus: {
      type: Function,
      required: true,
    },
  },
  data() {
    // colors
    const chartColors = {
      primaryColorShade: "#836AF9",
      yellowColor: "#ffe800",
      successColorShade: "#28dac6",
      warningColorShade: "#ffe802",
      warningLightColor: "#FDAC34",
      infoColorShade: "#299AFF",
      greyColor: "#4F5D70",
      blueColor: "#2c9aff",
      blueLightColor: "#84D0FF",
      greyLightColor: "#EDF1F4",
      tooltipShadow: "rgba(0, 0, 0, 0.25)",
      lineChartPrimary: "#666ee8",
      lineChartDanger: "#ff4961",
      labelColor: "#6e6b7b",
      grid_line_color: "rgba(200, 200, 200, 0.2)",
    };
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            // backgroundColor: $themeColors.white,
            titleFontColor: "#fff",
            bodyFontColor: "#fff",
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                // ticks: {
                //   stepSize: 10000,
                //   min: 0,
                //   max: 50000,
                //   fontColor: chartColors.labelColor,
                // },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            display: false,
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [],
          // "Math", "Science", "History", "English"
          datasets: [
            // {
            //   label: "Term 1",
            //   backgroundColor: "rgba(75, 192, 192, 0.2)",
            //   borderColor: "rgba(75, 192, 192, 1)",
            //   data: [85, 90, 78, 88], // Term 1 results per subject,
            //   fill: false,
            // },
            // {
            //   label: "Term 2",
            //   backgroundColor: "rgba(153, 102, 255, 0.2)",
            //   borderColor: "rgba(153, 102, 255, 1)",
            //   data: [80, 85, 82, 90], // Term 2 results per subject,
            //   fill: false,
            // },
            // {
            //   label: "Term 3",
            //   backgroundColor: "rgba(255, 159, 64, 0.2)",
            //   borderColor: "rgba(255, 159, 64, 1)",
            //   data: [88, 92, 80, 85], // Term 3 results per subject,
            //   fill: false,
            // },
            // {
            //   data: [
            //     80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210,
            //     // 200, 280,
            //   ],
            //   label: "English",
            //   borderColor: $themeColors.success,
            //   lineTension: 0.5,
            //   pointStyle: "circle",
            //   backgroundColor: $themeColors.success,
            //   fill: false,
            //   pointRadius: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBorderWidth: 5,
            //   pointBorderColor: "transparent",
            //   pointHoverBorderColor: $themeColors.white,
            //   pointHoverBackgroundColor: chartColors.success,
            //   pointShadowOffsetX: 1,
            //   pointShadowOffsetY: 1,
            //   pointShadowBlur: 5,
            //   pointShadowColor: chartColors.tooltipShadow,
            // },
            // {
            //   data: [
            //     80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290,
            //     360, 375,
            //   ],
            //   label: "Urdu",
            //   borderColor: $themeColors.danger,
            //   lineTension: 0.5,
            //   pointStyle: "circle",
            //   backgroundColor: chartColors.lineChartDanger,
            //   fill: false,
            //   pointRadius: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBorderWidth: 5,
            //   pointBorderColor: "transparent",
            //   pointHoverBorderColor: $themeColors.white,
            //   pointHoverBackgroundColor: chartColors.lineChartDanger,
            //   pointShadowOffsetX: 1,
            //   pointShadowOffsetY: 1,
            //   pointShadowBlur: 5,
            //   pointShadowColor: chartColors.tooltipShadow,
            // },
          ],
        },
      },
      marksChart: [],
      showChart: false,
      feesFields: [
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "balance", key: "balance" },
      ],
      attFields: [
        { label: "date", key: "date" },
        { label: "status", key: "status" },
        { label: "time", key: "time" },
      ],
      compFields: [
        { label: "date", key: "date" },
        { label: "from", key: "name" },
        { label: "complaint", key: "complaint" },
        { label: "actions", key: "actions" },
      ],
      parentFields: [
        { label: "date", key: "date" },
        { label: "for", key: "name" },
        { label: "complaint", key: "complaint" },
        { label: "actions", key: "parActions" },
      ],
      ptmFields: [
        { label: "date", key: "date" },
        { label: "summary", key: "summary" },
        "actions",
      ],
      assess: {
        subject: "",
      },
      subjects: ["English", "Urdu", "Mathhematics"],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      attendance: [],
      complaints: [],
      byparent: false,
      compObj: {
        id: 0,
        sID: this.$route.params.id,
        tID: 0,
        date: "",
        complaint: "",
        status: "pending",
        attachment: "",
        campusID: this.$store.state.userData.cId,
      },
      resObj: {},
      visibility: false,
      sidebarTitle: "",
      buttonTitle: "",
      mydocloading: false,
      mediaLoaded: false,
      visibility2: false,
      ptmData: [],
      ptmObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        sID: this.$route.params.id,
        date: "",
        summary: "",
      },
      addPtm: false,
      request: false,
      ptmLoading: false,
      dataLoading: false,
      examTypes: [],
      resultFields: [
        { label: "exam", key: "examType" },
        { label: "session", key: "session" },
        { label: "marks", key: "marks" },
        { label: "percentage", key: "percentage" },
        { label: "rank", key: "rank" },
        { label: "grade", key: "grade" },
        { label: "result", key: "result" },
        "actions",
      ],
      assessments: [],
      marksData: [],
      marksGrid: false,
      marksFields: [
        { label: "subject", key: "subject" },
        { label: "attendance", key: "attendance" },
        { label: "marks", key: "marks" },
        { label: "percentage", key: "percentage" },
        { label: "rank", key: "rank" },
        { label: "grade", key: "grade" },
        { label: "status", key: "status" },
      ],
      // fees: [
      //   {
      //     feeType: "Tution Fee",
      //     date: "9 Feb 2023",
      //     amount: 2000,
      //   },
      //   {
      //     feeType: "Annual Fee",
      //     date: "5 Jan 2023",
      //     amount: 5500,
      //   },
      // ],
      resources: [
        {
          subject: "English",
          notes: "",
        },
        {
          subject: "Physics",
          notes: "",
        },
      ],
      visibility3: false,
      visibility4: false,
      visibility5: false,
      feeObj: {
        sID: 0,
        year: 0,
        userID: this.$store.state.userData.userID,
        feesTypeIDs: [],
        months: [],
      },
      years: [
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030,
      ],
      months: [
        { text: "January", value: "Jan" },
        { text: "February", value: "Feb" },
        { text: "March", value: "Mar" },
        { text: "April", value: "Apr" },
        { text: "May", value: "May" },
        { text: "June", value: "Jun" },
        { text: "July", value: "Jul" },
        { text: "August", value: "Aug" },
        { text: "September", value: "Sep" },
        { text: "October", value: "Oct" },
        { text: "November", value: "Nov" },
        { text: "December", value: "Dec" },
      ],
      saving: false,
      selectedID: [],
      selectedFeeType: [],
      printing: false,
      accTypes: [],
      recObj: {
        grNo: "",
        familyCode: 0,
        amount: 0,
        rcvDate: "",
        userID: this.$store.state.userData.userID,
        campusID: this.$store.state.userData.cId,
        feesIDs: [],
        accountID: 0,
      },
      totalAmount: 0,
      receiving: false,
      admObj: null,
      instSaving: false,
      instObj: {},
      instCurrent: 0,
      disObj: {},
      visibility6: false,
      currentBalance: 0,
      netBalance: 0,
      savingDis: false,
      chDetails: null,
      orgList: [],
      visibility7: false,
      orgFields: [
        { label: "Name", key: "title" },
        { key: "actions", label: "actions" },
      ],
      otherObj: {
        organization: 0,
        bank: "",
        cheque: "",
      },
      orgObj: {},
      savingOrg: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      exempting: false,
      exObj: {},
    };
  },
  created() {
    this.$route.params.id = parseInt(this.$route.params.id);
    this.LoadSettings();
    this.LoadSettingsAll();
    this.loadAttendance("");
    this.loadComplaints();
    // this.loadExamCat();
    this.loadResult();
    // this.LoadMarkGraph();
    this.loadPtm();
    this.loadAccounts();
    if (
      this.reportDomain === "myskoolhpgs" &&
      ["registered", "enrolled"].includes(this.userData.status.toLowerCase())
    ) {
      this.feesFields.push({
        label: "actions",
        key: "actions",
        tdClass: "d-flex",
      });
    }
    // this.feesFields.push({
    //   label: "actions",
    //   key: "actions",
    //   tdClass: "d-flex",
    // });
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID,
        body: ["admission_feeIDs"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.admObj = res.find((el) => el.key == "admission_feeIDs");
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["cheque_details"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.chDetails = res.find((el) => el.key == "cheque_details");
      // console.log(this.chDetails);
      if (this.chDetails && this.chDetails.valueBool) this.LoadOrg();
    },
    async loadAttendance(filter) {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadAttendance?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.$route.params.id +
          "&duration=" +
          filter,

        token: this.$store.state.userData.token,
      };
      this.attendance = await this.get(obj);
      // console.log("att:", this.attendance);
      this.dataLoading = false;
    },
    async loadComplaints() {
      this.byparent = false;
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadComplaints?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.$route.params.id,

        token: this.$store.state.userData.token,
      };
      this.complaints = await this.get(obj);
      // console.log("com:", this.complaints);
      this.dataLoading = false;
    },
    async loadParent() {
      this.byparent = true;
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "SchoolComplaints/LoadDataStudent?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID +
          "&sID=" +
          this.$route.params.id,

        token: this.$store.state.userData.token,
      };
      this.complaints = await this.get(obj);
      // console.log("par", this.complaints);
      this.dataLoading = false;
    },
    async loadAccounts() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      // console.log("acc", result);
      if (result !== "NotFound") this.accTypes = result;
      else this.accTypes = [];
      if (
        this.reportDomain === "myskoolhpgs" &&
        this.$store.state.loggedUser.campusID != 1
      ) {
        this.accTypes = this.accTypes.filter((el) => [18, 20].includes(el.id));
      }
      // console.log(this.$store.state.loggedUser);
    },
    async LoadOrg() {
      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID +
          "&category=org",
        token: this.$store.state.userData.token,
      };
      this.orgList = await this.get(obj);
      // console.log(this.orgList);
    },
    openOrg() {
      this.orgObj = {
        id: 0,
        title: "",
        typee: "org",
        campusID: this.userData.campusID,
      };
      this.visibility7 = true;
      var elem = this.$refs["org_name"];
      elem.state = undefined;
    },
    checkOrg() {
      var elem = this.$refs["org_name"];
      if (this.orgObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveOrg() {
      if (this.checkOrg() == true) {
        this.savingOrg = true;
        if (this.orgObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "DropDownList?db=" +
              this.$store.state.userData.db,
            body: this.orgObj,
            message: "Organization added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "DropDownList/" +
              this.orgObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Organization updated successfully.",
            context: this,
            body: this.orgObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadOrg();
          this.orgObj = {
            id: 0,
            title: "",
            typee: "org",
            campusID: this.userData.campusID,
          };
          var elem = this.$refs["org_name"];
          elem.state = undefined;
        }
        this.savingOrg = false;
      }
    },
    async editOrg(acc) {
      this.orgObj = { ...acc };
      var elem = this.$refs["org_name"];
      elem.state = undefined;
    },
    async deleteOrg(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DropDownList/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Organization deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadOrg();
      }
    },

    setSelectAll(e) {
      // console.log(e);
      if (e) {
        this.$refs.dueTable.selectAllRows();
      } else {
        this.$refs.dueTable.clearSelected();
      }
    },
    setSelected(e, ind) {
      if (e) {
        this.$refs.dueTable.selectRow(ind);
      } else {
        this.$refs.dueTable.unselectRow(ind);
      }
      // console.log(e, ind);
    },
    onRowSelect(ch) {
      // console.log(ch);
      this.totalAmount = ch.reduce((total, c) => total + c.fees.balance, 0);
      this.selectedID = ch.reduce((acc, c) => {
        acc.push(c.fees.id);
        return acc;
      }, []);

      this.selectedFeeType = ch.reduce((acc, c) => {
        acc.push(c.fees.feesType);
        return acc;
      }, []);
      // console.log(this.selectedFeeType);
    },
    async print() {
      if (this.dues.length > 0) {
        this.printing = true;
        let controller = "GenerateChallanStudentLeft";
        let contr = "ChallanLeft";
        if (this.userData.status.toLowerCase() == "present") {
          controller = "GenerateChallanStudent";
          contr = "Challan";
        }
        var pObj = {
          fCode: 0,
          sID: this.$route.params.id,
          cID: [],
          secID: [],
          feeTypeID: [],
          months: [],
          year: 0,
          familyWise: true,
          userID: this.$store.state.userData.userID,
          feeIDs: this.selectedID,
        };
        var objData = await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.userData.campusID +
            "&exp=false",
          body: pObj,
          message: null,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (objData.length > 0) {
          let url =
            `https://${this.reportDomain}.myskool.app/Fee/${contr}?dbb=` +
            this.$store.state.userData.db +
            "&sID=" +
            this.$route.params.id +
            "&cID=" +
            this.userData.campusID +
            "&cls=&sec=&feeType=&fCode=-1&fcID=" +
            objData +
            "&duedate=" +
            new Date().toJSON().split("T")[0];

          window.open(url, "_blank");
        } else {
          this.$bvToast.toast("Nothing to print.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
        this.printing = false;
      } else {
        this.$bvToast.toast("No dues found", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    openInit() {
      this.$refs.dueTable.clearSelected();
      this.visibility3 = true;
      let ids = this.admObj.value.split(",").map((el) => parseInt(el));
      this.feeObj = {
        sID: this.$route.params.id,
        year: new Date().getFullYear(),
        userID: this.$store.state.userData.userID,
        feesTypeIDs: ids,
        // months: this.months[new Date().getMonth()].value,
        months: [],
      };
      var elem = this.$refs["month"];
      elem.state = undefined;
      // console.log(this.feeObj);
    },
    selectMonth(val) {
      let obj = this.feeObj.months.find((el) => el == val);
      if (obj) {
        this.feeObj.months = this.feeObj.months.filter((el) => el != val);
      } else this.feeObj.months.push(val);
    },
    CheckMonths() {
      var elem = this.$refs["month"];
      // this.feeObj.months == ""
      if (this.feeObj.months.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckYear() {
      var elem = this.$refs["year"];
      if (this.feeObj.year == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveFee() {
      this.CheckMonths();
      this.CheckYear();
      if (this.CheckMonths() == false || this.CheckYear() == false) {
        return this.$bvToast.toast("Please select the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // this.feeObj.months = [this.feeObj.months];
        // console.log(this.feeObj);
        this.saving = true;
        // "Fees/CreateFee?db=" +
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/CreateFeeInitial?db=" +
            this.$store.state.userData.db,
          body: this.feeObj,
          message: "fee created successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.visibility3 = false;
          this.$emit("load-dues");
        }
      }
    },
    openRec() {
      if (this.selectedID.length > 0) {
        this.visibility4 = true;
        this.recObj = {
          grNo: this.userData.grNo,
          familyCode: 0,
          amount: this.totalAmount,
          rcvDate: new Date().toJSON().split("T")[0],
          userID: this.$store.state.userData.userID,
          campusID: this.userData.campusID,
          feesIDs: this.selectedID,
          accountID: 0,
          receiveNote: "",
        };
        // console.log(this.recObj);
      } else {
        this.$bvToast.toast("Please select the receiving fee", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    CheckAcc() {
      var elem = this.$refs["acc"];
      if (this.recObj.accountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async receiveFee() {
      if (this.CheckAcc() == true) {
        if (this.totalAmount > 0) {
          this.receiving = true;
          if (this.chDetails && this.chDetails.valueBool) {
            this.recObj.feesChequeDetails = {
              id: 0,
              billID: 0,
              organizationID: this.otherObj.organization,
              bank: this.otherObj.bank,
              cheque: this.otherObj.cheque,
              otherDetails: "",
              campusID: this.userData.campusID,
            };
          } else this.recObj.feesChequeDetails = null;

          // "Fees/ReceiveFees?db=" +
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/ReceiveFeesNew?db=" +
              this.$store.state.userData.db +
              "&withLate=false",
            body: this.recObj,
            message: "Fee received successfully.",
            context: this,
            token: this.$store.state.userData.token,
            feeReceive: true,
          });
          this.receiving = false;
          // console.log(status);
          if (status) {
            if (this.userData.status.toLowerCase() == "registered") {
              this.checkFee(this.selectedFeeType);
            }

            this.visibility4 = false;
            let rem = this.duesTotal - this.totalAmount;
            let url =
              `https://${this.reportDomain}.myskool.app/Fee/Slip?dbb=` +
              this.$store.state.userData.db +
              "&bID=" +
              status +
              "&cID=" +
              this.userData.campusID +
              "&rem=" +
              rem;
            window.open(url, "_blank");
            this.$refs.dueTable.clearSelected();
            this.$emit("load-dues");
          }
        } else {
          this.$bvToast.toast("Receiving amount is 0", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      }
    },
    checkFee(item) {
      let fIDs = [...item];
      if (this.admObj && this.admObj.value) {
        let ids = this.admObj.value.split(",").map((el) => parseInt(el));

        const sameIDs = ids.filter((el) => fIDs.includes(el));
        // console.log(sameIDs);
        if (sameIDs.length > 0) this.updateStudent();
      }
    },
    async updateStudent() {
      this.userData.status = "enrolled";
      var status = await this.put({
        url:
          this.$store.state.domain +
          "students/" +
          this.userData.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.userData,
        message: "Student marked as enrolled.",
        context: this,
        token: this.$store.state.userData.token,
      });
    },

    openInst(item) {
      // console.log(item);
      this.instObj = {
        feeID: item.fees.id,
        amount: null,
        campusID: this.userData.campusID,
      };
      this.instCurrent = item.fees.balance;
      this.$refs["instAmount"].state = undefined;
      this.visibility5 = true;
    },
    checkInstallment() {
      var elem = this.$refs["instAmount"];
      let x = parseInt(this.instObj.amount);
      if (isNaN(x) || x <= 0 || x >= this.instCurrent) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveInstallment() {
      if (this.checkInstallment() == true) {
        this.instObj.amount = parseInt(this.instObj.amount);
        // console.log(this.instObj);
        this.instSaving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/BreakFee?db=" +
            this.$store.state.userData.db,
          body: this.instObj,
          message: "Installment added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.instSaving = false;
        if (status) {
          this.visibility5 = false;
          this.$refs.dueTable.clearSelected();
          this.$emit("load-dues");
        }
      }
    },
    openDiscount(item) {
      // console.log(item);
      this.disObj = {
        feesID: item.fees.id,
        discountAmount: item.fees.discount,
        userID: this.$store.state.userData.userID,
        timelyDiscount: 0,
        discountNote: item.fees.discountNote,
      };
      this.currentBalance = item.fees.actualFee;
      this.netBalance = item.fees.actualFee - item.fees.discount;
      this.$refs["discount"].state = undefined;
      this.visibility6 = true;
    },
    checkDiscount() {
      var elem = this.$refs["discount"];
      let x = parseInt(this.disObj.discountAmount);
      if (isNaN(x) || x >= this.currentBalance) {
        return (elem.state = false);
      } else {
        this.disObj.discountAmount = x;
        return (elem.state = true);
      }
    },
    validateDiscount() {
      if (this.checkDiscount() == true) {
        this.netBalance = this.currentBalance - this.disObj.discountAmount;
      } else {
        this.netBalance = this.currentBalance;
      }
    },
    async AddDiscount() {
      if (this.checkDiscount() == false) {
        return this.$bvToast.toast("Please enter the discount amount.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.disObj.discountAmount = parseInt(this.disObj.discountAmount);
        // console.log(this.disObj);
        this.savingDis = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/AddDiscount?db=" +
            this.$store.state.userData.db,
          body: this.disObj,
          message: "Discount added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingDis = false;
        if (status) {
          this.visibility6 = false;
          this.$refs.dueTable.clearSelected();
          this.$emit("load-dues");
        }
      }
    },
    openExempt(id) {
      this.exObj = {
        feeID: id,
        reason: "",
      };
      this.$bvModal.show("modal-exempt");
    },
    checkExReason() {
      var elem = this.$refs["ex_reason"];
      if (this.exObj.reason == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async ExemptWithNote() {
      if (this.checkExReason() == true) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to exempt this fee?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          // console.log(this.exObj);
          this.exempting = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/ExemptFee?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.userData.campusID +
              "&feeID=" +
              this.exObj.feeID +
              "&reason=" +
              this.exObj.reason +
              "&userID=" +
              this.$store.state.userData.userID,
            message: "Fee exempt successfully.",
            context: this,
            body: null,
            token: this.$store.state.userData.token,
          });
          this.exempting = false;
          if (status) {
            this.$bvModal.hide("modal-exempt");
            this.$refs.dueTable.clearSelected();
            this.$emit("load-dues");
          }
        }
      }
    },
    async Exempt(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to exempt this fee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/ExemptFee?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.userData.campusID +
            "&feeID=" +
            id,
          message: "Fee exempt successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.$refs.dueTable.clearSelected();
          this.$emit("load-dues");
        }
      }
    },

    openComp() {
      this.compObj = {
        id: 0,
        sID: this.$route.params.id,
        tID: 0,
        date: "",
        complaint: "",
        status: "pending",
        attachment: "",
        campusID: this.userData.campusID,
      };
      this.sidebarTitle = "Add Complaint";
      this.buttonTitle = "Save";
      this.visibility = true;
      this.mediaLoaded = false;

      var elem = this.$refs["complaint"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    CheckComp() {
      var elem = this.$refs["complaint"];
      if (this.compObj.complaint == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCompDate() {
      var elem = this.$refs["compDate"];
      if (this.compObj.date == "" || this.compObj.date == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    removeAtt() {
      this.compObj.attachment = null;
      this.mediaLoaded = false;
      this.$refs.attachment.files = null;
    },

    uploadAtt() {
      if (this.$refs.attachment.files.length !== 0) {
        this.mydocloading = true;
        this.mediaLoaded = false;
        var formData = new FormData();
        formData.append("file", this.$refs.attachment.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.compObj.attachment = fn1;
            // console.log(this.compObj.attachment);
            this.mydocloading = false;
            this.mediaLoaded = true;
          })
          .catch((error) => {
            console.log("error", error);
            this.mydocloading = false;
          });
      }
    },
    EditComp(item) {
      this.compObj = { ...item };
      this.sidebarTitle = "Edit Complaint";
      this.buttonTitle = "Update";
      this.visibility = true;

      if (this.compObj.attachment == null || this.compObj.attachment == "")
        this.mediaLoaded = false;
      else this.mediaLoaded = true;

      var elem = this.$refs["complaint"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    async saveComp() {
      // console.log(this.compObj);
      if (this.CheckComp() == true && this.CheckCompDate() == true) {
        if (this.compObj.id == 0) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Complaints?db=" +
              this.$store.state.userData.db,
            body: this.compObj,
            message: "Complaint added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.loadComplaints();
            this.visibility = false;
          }
        } else {
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Complaints/" +
              this.compObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.compObj,
            message: "Complaint updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.loadComplaints();
            this.visibility = false;
          }
        }
      }
    },
    async DeleteComp(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Complaints/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Complaint removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadComplaints();
      }
    },
    resolve(item) {
      this.resObj = { ...item };
      this.visibility2 = true;
    },
    CheckResName() {
      var elem = this.$refs["resBy"];
      if (this.resObj.resolvedBy == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckNote() {
      var elem = this.$refs["note"];
      if (this.resObj.note == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckResDate() {
      var elem = this.$refs["resdate"];
      if (this.resObj.resolvedOn == "" || this.resObj.resolvedOn == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      // console.log("ob", this.resObj);
      if (
        this.CheckNote() == true &&
        this.CheckResDate() == true &&
        this.CheckResName() == true
      ) {
        this.request = true;
        this.resObj.status = "resolved";
        var status = await this.put({
          url:
            this.$store.state.domain +
            "schoolComplaints/" +
            this.resObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.resObj,
          message: "Complaint resolved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;

        if (status) {
          this.loadParent();
          this.visibility2 = false;
        }
      }
    },
    async loadExamCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID,
        token: this.$store.state.userData.token,
      };
      this.examTypes = await this.get(obj);

      // console.log("exam cat", this.examTypes);
    },
    View(item) {
      this.marksData = item;
      this.marksGrid = true;
    },
    async loadResult() {
      var obj = {
        url:
          this.$store.state.domain +
          "Marks/StudentResult?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID +
          "&sID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.assessments = res[0].results;
      // console.log(this.assessments);
    },
    async LoadMarkGraph() {
      var obj = {
        url:
          this.$store.state.domain +
          "Marks/AssessmentGraph?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.userData.campusID +
          "&sID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.marksChart = res.filter((el) => el.marks.length > 0);
      // console.log(this.marksChart);

      let labels = [];
      let datasets = [];
      this.marksChart.forEach((el, ind) => {
        if (ind <= 3) {
          labels.push(el.subject);
          let obj = {
            data: el.marks.map((elem) => elem.obtained),
            label: "",
            borderColor: $themeColors[this.color[ind]],
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: $themeColors[this.color[ind]],
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: $themeColors[this.color[ind]],
            pointHoverBackgroundColor: $themeColors[this.color[ind]],
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: "rgba(0, 0, 0, 0.25)",
          };
          datasets.push(obj);
        }
      });
      // console.log(datasets, labels);
      this.lineChart.data.labels = labels;
      this.lineChart.data.datasets = datasets;
      this.showChart = true;
    },
    async loadPtm() {
      this.ptmLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "ParentTeachersMeeting/LoadData?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.ptmData = await this.get(obj);
      // console.log("ptm:", this.ptmData);
      this.ptmLoading = false;
    },

    open() {
      this.addPtm = true;
      this.ptmObj = {
        id: 0,
        campusID: this.userData.campusID,
        sID: this.$route.params.id,
        date: "",
        summary: "",
      };
      var elem = this.$refs["summary"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    Edit(item) {
      this.ptmObj = { ...item };
      this.addPtm = true;
      var elem = this.$refs["summary"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "ParentTeachersMeeting/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Summary removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadPtm();
      }
    },
    CheckSummary() {
      var elem = this.$refs["summary"];
      if (this.ptmObj.summary === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (this.ptmObj.date === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async Add() {
      this.CheckDate();
      this.CheckSummary();
      if (this.CheckDate() == true && this.CheckSummary() == true) {
        if (this.ptmObj.id == 0) {
          this.request = false;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "ParentTeachersMeeting?db=" +
              this.$store.state.userData.db,
            body: this.ptmObj,
            message: "Summary added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.loadPtm();
            this.addPtm = false;
          }
        } else {
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "ParentTeachersMeeting/" +
              this.ptmObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.ptmObj,
            message: "Summary updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.loadPtm();
            this.addPtm = false;
          }
        }
      }
    },
  },
};
</script>

<style>
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
