<template>
  <div>
    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="myObj === 'NotFound'">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id.
        Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert> -->

    <b-row align-h="center" v-if="studentLoading">
      <b-spinner
        class="mt-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>

    <template v-else-if="!showError">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="4" lg="5" md="12">
          <user-view-user-info-card
            :user-data="myObj"
            :item="item"
            :campuses="campuses"
            :getCampus="getCampus"
          />
        </b-col>
        <b-col cols="12" xl="8" lg="7" md="12">
          <user-view-user-plan-card
            :dues="dues"
            :history="history"
            :duesTotal="duesTotal"
            :user-data="myObj"
            :gridLoading="gridLoading"
            :showReceive="showReceive"
            :getCampus="getCampus"
            @load-dues="loadData()"
          />
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card />
        </b-col>
      </b-row>

      <invoice-list /> -->
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink, BSpinner } from "bootstrap-vue";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import userStoreModule from "../userStoreModule";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["item"],
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BSpinner,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    InvoiceList,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name == "student-enrolled") {
        vm.showReceive = true;
      }
      // console.log(to, from, vm.showReceive);
    });
  },
  created() {
    // console.log(this.item);
    // this.myObj = this.items;
    this.LoadStudent();
    if (this.reportDomain === "myskoolhpgs") {
      this.LoadCampuses();
    }
  },
  data() {
    return {
      myObj: {},
      gridLoading: false,
      studentLoading: false,
      dues: [],
      history: [],
      duesTotal: 0,
      complaints: [],
      showError: false,
      campuses: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      showReceive: false,
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "-";
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    async LoadStudent() {
      this.studentLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/GetSelected?id=" +
          this.$route.params.id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("obj:", this.myObj);

      this.studentLoading = false;
      this.loadData();
    },
    async loadData() {
      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Fees/GetStudentFeesDataExemption?db=" +
      //     this.$store.state.userData.db +
      //     "&campusID=" +
      //     // this.$store.state.userData.cId +
      //     this.myObj.campusID +
      //     "&grNo=" +
      //     this.myObj.grNo +
      //     "&familyCode=0",
      //   token: this.$store.state.userData.token,
      // };
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Fees/GetStudentFeesRecord?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.myObj.campusID +
          "&sID=" +
          this.myObj.id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log("res", res);
      if (res === "No record found") {
        this.gridLoading = false;
        this.dues = [];
        this.history = [];
        this.duesTotal = 0;
      } else {
        this.dues = res[0].dues;
        this.history = res[0].history;
        this.duesTotal = res[0].duesTotal;

        this.gridLoading = false;
      }
    },
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style></style>
